@import url('https://fonts.googleapis.com/css?family=Roboto');

@font-face {
  font-family: 'KyoboHandwriting2020A';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2112@1.0/KyoboHandwriting2020A.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ACCchildrenheartOTF-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2112-2@1.0/ACCchildrenheartOTF-Regular.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SBAggroB';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

.bg-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.65;
}

/* Global Styles */
:root {
  --primary-color: #dc3545;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  opacity: 0.7;
}

ul {
  list-style: none;
}

.profile_pic {
  height: auto;
  max-width: 40vh;
  border: 7px solid rgb(252, 249, 247);
  box-shadow: 0px 0px 44px black;
}

/* Utilities */
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-success {
  color: var(--success-color);
}

.text-danger {
  color: var(--danger-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Center All */
.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 0.3rem 1rem;
  margin-right: 0.2rem;
}

.badge {
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}

.btn:hover {
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
}

input[type='submit'],
button {
  font: inherit;
}

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 2rem;
  z-index: 1;
  width: 100%;
  opacity: 0.9;
  margin-bottom: 1rem;
}

.navbar ul {
  display: flex;
}

.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}

.navbar a:hover {
  color: var(--light-color);
}

.profile_margin {
  margin-top: 40px;
}

.btn {
  font-family: 'KyoboHandwriting2020A', Arial, Helvetica, sans-serif;
  margin-left: 30px;
  border-radius: 12px;
  box-shadow: 3px 3px 10px #100f0f;
  font-size: 26px;
  margin-top: 35px;
  margin-bottom: 25px;
}

.sns {
  color: #333;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 80px;
}

.fa-linkedin {
  margin-right: 10px;
}

.fas {
  transform: scale(0.8, 0.9);
  margin-left: 1px;
}

.name {
  font-family: 'ACCchildrenheartOTF-Regular', Arial, Helvetica, sans-serif;
  font-size: 70px;
  font-weight: bold;
  color: #fffff0;
  text-shadow: 4px 4px 5px black;
  letter-spacing: 7px;
}

h2 {
  font-family: 'KyoboHandwriting2020A', Arial, Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #f5f3ff;
  text-shadow: 2px 2px 10px black;
}

.mobile-img {
  display: none;
}

span {
  color: #f7f8ff;
  text-shadow: 4px 4px 2px black;
}

body {
  background: black;
  width: 100%;
  z-index: -99;
}

@media (max-width: 900px) {
  .bg-video {
    display: none;
  }
  .mobile-img {
    display: block;
    visibility: visible;
    position: fixed;
    top: 25;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 50%;
    min-height: 50%;
    z-index: -50;
    opacity: 0.7;
  }

  body {
    background: black;
    width: 100%;
    z-index: -99;
  }

  .profile_margin {
    margin-top: 50px;
  }

  .profile_pic {
    max-width: 30vh;
    border: 3px solid rgb(252, 249, 247);
  }

  .name {
    letter-spacing: 3px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 55px;
  }

  h2 {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 18px;
  }

  span {
    text-shadow: 2px 2px 2px black;
  }

  .sns {
    font-size: 70px;
    margin-top: 6px;
    margin-left: 8px;
    margin-right: 2px;
    color: rgb(36 36 36);
  }
  .fa-linkedin {
    margin-right: -2px;
    margin-left: 12px;
  }

  .btn {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 4px;
  }
}
